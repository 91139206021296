<template>
    <div class="body">
        <div class="container" v-if="info">
            <div class="header full-width" >
                <div class="title">
                    <span class="timer">倒计时<span class="minute">{{leftText}}</span></span>
                    <span class="desc light-color">第{{cur+1}}题/共{{count}}题</span>
                </div>
                <div class="question-nums clearfix">
                    <span  :class="index == cur? 'num cur':'num'"
                        v-for="(item, index) in count"
                        :key="index"
                        >
                    {{index+1}}
                    </span>
                </div>
            </div>
            <div class="question-body">
                <div class="category">{{info.detail.category}}</div>
                <div class="question-title">
                    {{info.detail.title}}<span class="light-color">（{{info.detail.score}}分）</span>
                </div>
                <div class="question-detail" v-if="info.detail.category=='多选题'">
                    <van-checkbox-group v-model="result2" @change=onchange2>
                      <van-checkbox :name="i" 
                      :key="i"
                      v-for="(o, i) in getOptions(info.detail.options)"
                      >{{letters[i]}} {{o}}</van-checkbox>
                    </van-checkbox-group>
                </div>
                <div class="question-detail radio" v-if="info.detail.category=='单选题'">
                    <van-radio-group v-model="result1" @change=onchange1>
                     <van-radio :name="i"
                     :key="i"
                     v-for="(o, i) in getOptions(info.detail.options)"
                     >{{letters[i]}} {{o}}</van-radio>
                    </van-radio-group>
                </div>
                <!-- <div>
                    <van-checkbox-group v-model="result">
                      <van-checkbox name="a">A 签到</van-checkbox>
                      <van-checkbox name="b">B 看课程视频</van-checkbox>
                      <van-checkbox name="b">C 考试</van-checkbox>
                    </van-checkbox-group>
                </div> -->
            </div>
            <div class="footer full-width">
                <a class="btn" :class="cur ==0?'hide': ''" @click="toPrev">&lt; 上一题</a>
                <van-button type="info" @click="toFinish">交卷</van-button>
                <a class="btn" :class="cur ==count-1?'hide': ''" @click="toNext">下一题 &gt;</a>
            </div>
        </div>
    </div>
</template>

<script>
    import { getExam, getQuestion, saveQuestionResult, finishExam, finishCourseExam } from '../../api/task.js';
    import { mapState } from 'vuex';
    import {formatSecond, checkLogin} from '../../utils/common.js';
    var examid, tid, timer, type;
    export default{
        activated() {
            examid = this.$route.query.examid; // 课程id
            tid = this.$route.query.tid; // 任务ID，用于计算进度，并更新总进度 
            type = this.$route.query.type;
            checkLogin().then(()=> {
                this.getPageData();
            })
            this.hideTab();
        },
        deactivated() {
            clearInterval(timer);
        },
        computed: mapState(['token']),
        data() {
           return{
              questions:[],
               cur: 0,
               count: 0,
               info: null,
               result1: '',
               result2: [],
               letters: ['A', 'B', 'C', 'D', 'E','F', 'G'],
               answers: [],
               minute: 0,
               left : 0,
               leftText: ''
           }
        },
        methods:{
            getPageData(){
                Promise.all([this.getQuestion(), this.getExam()]);
            },
            toFinish(){
                //this.$router.push('/result');
                
                console.log('qs', this.questions);
                console.log('as', this.answers);
                var results = [], l = this.questions.length;
                for(let i=0; i <l; i++){
                    var eq = this.questions[i];
                    var a = this.answers[i];
                    var d = {
                        examid: examid,
                        exquid: eq.id,
                        score: eq.detail.score,
                    }
                    if(typeof(a) == 'undefined'){
                        d['status'] = 2; //标注作废
                    }else{
                        if(eq.detail.category == '单选题'){
                            // 答案是数字 或者是数字类型的字符串
                            if(typeof(a) == 'number' || typeof(a) == 'string' && a.length>0){
                                d['result'] = a;
                                d['status'] = 1;
                            }else{
                                d['status'] = 2; //标注作废
                            }
                        }else{
                            if(a.length){
                                d['result'] = a.join('|');
                                d['status'] = 1; 
                            }else{
                                 d['status'] = 2; //标注作废
                            }
                        }
                    }
                    results.push(d);
                }
                var waste = this.minute - this.left;
                var data ={
                    use_times: waste,
                    results: results,
                };
                console.log('data', data);
                clearInterval(timer);
                var fc = type =='c' ? finishCourseExam : finishExam;
                fc(examid, tid, data).then(()=>{
                    this.$router.push(`/result?tid=${tid}&examid=${examid}&type=${type}`);
                });
            },
            getExam(){
                 getExam(examid, tid).then((ret)=>{
                     if(ret){
                        var minute = ret.data.minute;
                        this.left = this.minute =  Number(minute)* 60;
                        this.leftText = this.format(this.minute);
                        var that = this;
                        timer = setInterval(()=>{
                            that.left = this.left - 1;
                            that.leftText = that.format(that.left);
                            if(that.left == 0){
                                clearInterval(timer);
                                that.$dd.device.notification.alert({
                                    message: "考试时间到",
                                    title: "提示",
                                    buttonName: '交卷',
                                    onSuccess : function() {
                                        var data ={
                                            use_times: that.minute
                                        }
                                        that.toFinish(examid, tid, data)
                                        // console.log('jiaojuan', data);
                                    },
                                });
                                // 自动交卷
                            }
                        }, 1000);
                     }
                 })
             },
            getQuestion(){
                getQuestion(examid).then((ret)=>{
                    if(ret){
                        this.questions = ret.data;
                        this.count = ret.data.length;
                        this.cur = 0;
                        this.info = this.questions[0];
                        console.log('1111',this.count);
                    }
                })
                
            },
            getOptions(options){
                return options.split('|');
            },
            toPrev(){
                this.cur = this.cur - 1;
                this.changePage();
            },
            toNext(){
                this.cur = this.cur + 1;
                this.changePage();
            },
            // 切换试题处理
            
            changePage(){
                this.info = this.questions[this.cur];
                var answer = this.answers[this.cur];
                // 如果已经保存，就赋予 有可能是0
                if(typeof(answer) != 'undefined'){
                    if(this.info.detail.category == '单选题'){
                        this.result1 = answer
                    }else{
                        this.result2 = answer
                    }
                }else{
                    this.result1 = '';
                    this.result2 = [];
                }
            },
            onchange1(){
                this.answers[this.cur] = this.result1;
                // this.save(this.result1)1;
            },
            onchange2(){
                this.answers[this.cur] = this.result2;
                // this.save(this.result2.join('|'));
            },
            save(res){
                var eqid = this.info.id;
                var data = {
                    result: res,
                    examid: examid,
                    score: this.info.detail.score
                };
                saveQuestionResult(eqid, data);
            },
            getTimerText(left){
                var m = Math.floor(left/60);
                var s = left%60;
                return `${m}:${s}`
            },
            format(s){
             return formatSecond(s);
            },
            hideTab(){
                //console.log(document.querySelector('#vantab'));
                document.querySelector('#vantab').classList.add('tab-hide');
            }
        }
    }
</script>

<style scoped>
    @import url('../../assets/css/common.css');
    .body{
        background-color: #f2f2f2;
    }
    .container{
        position: relative;
        height: 100vh;
        text-align: left;
        color: #000;
        display: flex;
        flex-direction: column;
    }
    .header{
        font-size: 12px;
        padding-top: 2.0rem;
        background-color: #fff;
        padding: 2.0rem 1.5rem 0.1rem 1.5rem;
    }
    .header .timer{
        color: #bb5d55;
        padding-right: 1.9rem;
    }
    .header .minute{
        padding-left: 2px;
        font-size: 14px;
    }
    .header .desc{
        font-size: 14px;
    }
    .question-nums{
        margin-top: 0.2rem;
    }
    .question-nums .num{
        display: block;
        width: 3.3rem;
        height: 3.3rem;
        line-height: 3.3rem;
        text-align: center;
        background-color: #f1f1f1;
        border-radius: 3px;
        margin-right: 0.15rem;
        float: left;
        margin-bottom: 0.4rem;
        color: #666;
        font-size: 14px;
    }
    .question-nums .num.cur{
        color: #fff;
        background-color: #465ecc;
    }
    .question-nums .num:nth-child(10){
        margin-right: 0;
    }
    .question-body{
        background-color: #fff;
        margin-top: 1.5rem;
        padding: 2rem 1.5rem ;
        font-size: 14px;
        border-radius: 3px;
        height: 100%;
    }
    .question-body .category{
        color: #465ecc;
    }
    .question-body .question-title{
        padding: 9px 0;
    }
    .question-body .question-detail .van-checkbox{
        height: 30px;
        line-height: 30px;
    }
    .question-body .question-detail .van-radio{
        height: 30px;
        line-height: 30px;
    }
    .footer{
        position: absolute;
        display: flex;
        height: 4.5rem;
        bottom: 0;
        border-top: 1px solid #e8e8e8;
        background-color: #fff;
        justify-content: space-evenly;
        font-size: 16px;
        padding: 8px 0;
    }
    .footer .btn{
        text-align: center;
        display: block;
        width: 6.6rem;
        height: 3.7rem;
        line-height: 3.7rem;
    }
    .footer button{
        width: 16.8rem;
        height: 3.7rem;
        line-height: 3.7rem;
        background-color: #465ecc;
    }
    .footer .hide{
        visibility: hidden;
    }
</style>